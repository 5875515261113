import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `

    <div class="panelLogo">
      <span class="created-by">
        Form created by Viant's
      </span>
      <div class="logo-container">
        <div>
          <img class="logo-footer" src="./assets/images/logo.png" alt="OpsPanel Logo">
          <span class="logo-title">BIOD</span>
        </div>
      </div>
    </div>


    <!--
    <div class="socials">
      <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
    -->
  `,
})
export class FooterComponent {
}
