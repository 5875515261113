import { Observable } from 'rxjs';

export interface Field {
  field_name: string;
  field_type: string;
  is_required: boolean;
  is_dropdown: boolean;
  field_dropdown: string[];
  is_search: boolean;
  search_field: string;
  search_table: string;
  tooltip_desc: string;
  csv_upload: boolean;
}

export interface SubmitField{
  name: string;
  value: string;
  type: string;
  mode?: string;
}

export interface ReportFields {
  report_name: string;
  report_hyperlink: string;
  custom_messaging: string;
  fields: Field[];
}

export interface ResponseData {
  status_code: number;
  message: string;
}

export interface SubmitFields {
  //user_email: string;
  report_name: string;
  fields: SubmitField[];
}

export interface SearchVals {
  search_results: string[]
}

export interface EncryptionData {
  value: string;
}

export interface UploadFileData {
  gbq_project: string,
  gbq_dataset: string,
  gbq_table: string,
}

// {
// "report_name": "Domains",
// "access_key": "abc1234",
// "fields": [{
//     "field_name": "Email",
//     "field_value": "abc@viantinc.com"
// },
// {
//     "field_name": "Enter IDs",
//     "field_value": "123"
// },
// {
//     "field_name": "Start Date",
//     "field_value": "2020-01-01"
// },
// {
//     "field_name": "End Date",
//     "field_value": "2020-03-01"
// }]

export abstract class ReportData {
  abstract getAllData(id:string): Observable<any[]>;
  abstract getCSVData(url:string): Observable<any>;
  abstract getData(reportName:string, id:string): Observable<ReportFields>;
  abstract postData(data:SubmitFields, id:string): Observable<ResponseData>;
  abstract uploadFile(file: File, reportName:string, id:string): Observable<any>;
  abstract getSearchData(val:string, search_field:string, search_table:string, id:string): Observable<SearchVals>;
  abstract getDependencyData(searchPath:string, id:string): Observable<SearchVals>;
  abstract decode(msg:string): Observable<EncryptionData>;
}
