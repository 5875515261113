<div class="header-container">
  <div class="logo-container">

    <a class="logo" href="https://help.viantinc.com/hc/en-us">
      <img src="../../assets/images/viant-logo-v1.svg" alt="Logo">
    </a>
  </div>
  <!--
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>
