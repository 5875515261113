import { Injectable } from '@angular/core';
import { ReportFields, SubmitFields, ResponseData, SearchVals, ReportData, EncryptionData, UploadFileData } from '../data/api-mock';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of as observableOf , Observable } from 'rxjs';

@Injectable()
export class ApiMockService extends ReportData {

  private data: ReportFields = {
    'report_name': 'Viant Places Custom Segment',
    'report_hyperlink':'',
    'custom_messaging':'',
    'fields': [{
      'field_name': 'Segment Drop Type',
      'field_type': 'STRING',
      'is_required': false,
      'is_dropdown': true,
      'field_dropdown': ['Cross Device', 'Identity', 'Mobile Only'],
      'is_search': false,
      'search_field': '',
      'search_table': '',
      'csv_upload': false,
      'tooltip_desc': ''
    }, {
      'field_name': 'Search Business Name',
      'field_type': 'STRING',
      'is_required': false,
      'is_dropdown': false,
      'field_dropdown': [],
      'is_search': true,
      'search_field': '',
      'search_table': 'business name',
      'csv_upload': false,
      'tooltip_desc': ''
    }, {
      'field_name': 'Business',
      'field_type': 'STRING',
      'is_required': true,
      'is_dropdown': false,
      'field_dropdown': [],
      'is_search': true,
      'search_field': '',
      'search_table': 'business',
      'csv_upload': false,
      'tooltip_desc': ''
    }, {
      'field_name': 'Adelphic AID',
      'field_type': 'NUMBER',
      'is_required': true,
      'is_dropdown': true,
      'field_dropdown': ['1111', '2222', '3333'],
      'is_search': false,
      'search_field': '',
      'search_table': '',
      'csv_upload': false,
      'tooltip_desc': ''
    }, {
      'field_name': 'Visitation Start Date',
      'field_type': 'DATE',
      'is_required': true,
      'is_dropdown': false,
      'field_dropdown': [],
      'is_search': false,
      'search_field': '',
      'search_table': '',
      'csv_upload': false,
      'tooltip_desc': ''
    }, {
      'field_name': 'Visitation End Date',
      'field_type': 'DATE',
      'is_required': true,
      'is_dropdown': false,
      'field_dropdown': [],
      'is_search': false,
      'search_field': '',
      'search_table': '',
      'csv_upload': false,
      'tooltip_desc': ''
    }, {
      'field_name': 'Segment Name',
      'field_type': 'STRING',
      'is_required': true,
      'is_dropdown': false,
      'field_dropdown': [],
      'is_search': false,
      'search_field': '',
      'search_table': '',
      'csv_upload': false,
      'tooltip_desc': ''
    },
    {
      'field_name': 'Site ID',
      'field_type': 'STRING',
      'is_required': true,
      'is_dropdown': false,
      'field_dropdown': [],
      'is_search': false,
      'search_field': '',
      'search_table': '',
      'csv_upload': false,
      'tooltip_desc': ''
    }
  ],
  };

  private advertisersUrl = {
    staging: 'https://viant-bi-odm-staging.uc.r.appspot.com/advertisers',
    prod: 'https://viant-data-science.appspot.com/advertisers'
  }

  private searchUrl = {
    staging: 'https://viant-bi-odm-staging.uc.r.appspot.com/searches',
    prod: 'https://viant-data-science.appspot.com/searches'
  }

  private reportUrl = {
    staging: 'https://viant-bi-odm-staging.uc.r.appspot.com/reports',
    prod: 'https://viant-data-science.appspot.com/reports'
  }

  private uploadUrl = {
    staging: 'https://viant-bi-odm-staging.uc.r.appspot.com/upload',
    prod: 'https://viant-data-science.appspot.com/upload'
  }

  private responseData: ResponseData = {
    status_code: 200,
    message: 'success',
  };

  private searchData = {
    'business name' : {search_results : ['McDonald\'s', 'Macy\'s', 'Walmart', 'HomeDepot', 'Walnut']},
    'business': ['Retail', 'Travel', 'Media', 'Financial Services']
  }

  private apiEndpoint = {
    advertisers: this.advertisersUrl.prod, //'https://viant-data-science.appspot.com/advertisers',
    report: this.reportUrl.prod, //this.reportUrl.prod,
    search: this.searchUrl.prod,
    upload: this.uploadUrl.prod,
  }

  /*
  private key = 'AIzaSyBnZZrU6MgeROcsYVcuFvQjLTPf9B8ZPy4';

  private optionsAuth = {
    headers: new HttpHeaders({'Authorization': this.key})
  };

  private options = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };*/

  constructor(private _http: HttpClient) {
    super();
  }

  authHeader(id: string, options:object = {}): object {
    let header =  new HttpHeaders( {'Authorization': id });
    Object.keys(options).forEach( key => header = header.append(key, options[key]))

    return { headers: header }
  }

  decode(msg: string): Observable<EncryptionData>{

    //decrypt test
    const data = {
      action: "decrypt",
      msg: msg,
      passcode: ""
    }

    //submitting post as plain text to get around CORS preflight issue with google webapp
    //return this._http.post<EncryptionData>(this._encryptUrl + data2, '');
    return observableOf({value: msg});
  }

  getAllData(id:string): Observable<any[]> {
    return this._http.get<any[]>(this.apiEndpoint.report, this.authHeader(id) );
  }

  getData(reportName, id): Observable<ReportFields> {
    console.log('getData', reportName, id);
    //return observableOf(this.data);

    return this._http.get<ReportFields>(this.apiEndpoint.report + `/${reportName}`, this.authHeader(id) );
  }

  getCSVData(url): Observable<any> {
    return this._http.get(url, {responseType: 'text'});
  }

  uploadFile(file, reportName, id): Observable<UploadFileData> {
    let formData:FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('report_name', reportName);
    return this._http.post<UploadFileData>(this.apiEndpoint.upload, formData, this.authHeader(id, { 'Accept': 'application/json' }));
  }

  getDependencyData(searchPath, id): Observable<SearchVals> {
    //http://viant-data-science.appspot.com/advertisers/{advertiser}/campaigns
    //http://viant-data-science.appspot.com/advertisers/{advertiser}/campaigns/{campaign}/ad_orders
    return this._http.get<SearchVals>(this.apiEndpoint.advertisers+searchPath, this.authHeader(id, {'Content-Type': 'application/json'}));
  }

  getSearchData(value, search_field, search_table, id): Observable<SearchVals> {
    const request = {
      "search_table": search_table,
      "search_field": search_field,
      "search_value": value
    }
    //const filterValue = value.toLowerCase();
    //console.log('search data table', this.searchData[search_table].filter(optionValue => optionValue.toLowerCase().includes(filterValue)));
    //return observableOf( this.searchData['business name']);

    console.log('from search function ', this.authHeader(id, {'Content-Type': 'application/json'}));

    return this._http.post<SearchVals>(this.apiEndpoint.search, JSON.stringify(request), this.authHeader(id, {'Content-Type': 'application/json'}));
  }

  postData(data:SubmitFields, id): Observable<any> {

    return this._http.post<any>(this.apiEndpoint.report, JSON.stringify(data), this.authHeader(id, {'Content-Type': 'application/json'}));
    //return observableOf(this.responseData);
    
  }

}
